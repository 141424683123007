var $imageHeight = $('article.post-type-project .thumbnail-image').outerHeight();
var $overlay = $('.post-project.bg-overlay');
var $bgColor = $overlay.data('bg-color');
var $project = $('article.post-type-project');
var $projectTextColor = $('article.post-type-project').data('textcolor');
$overlay.css({
    'background-color': $bgColor,
});
$overlay.css({
    'opacity': 1
});
setTimeout(() => {
    $overlay.css({
        'transition': 'none'
    });
}, 500);

$('body').addClass('text-color-' + $projectTextColor);

if($project.hasClass('no-fade-out')) {
    if($projectTextColor == 'white') {
        $('footer').css({
            'border-color' : '#dcdcdc'
        });
    }
    else {
        $('footer').css({
            'border-color' : '#484848'
        });
    }
}


function backgroundTransition($speed) {
    var scrollPos = $(document).scrollTop();
    var targetOpacity = 0;
    if (scrollPos > 0) {
        targetOpacity = ((($imageHeight * $speed) - scrollPos) / ($imageHeight * $speed)).toFixed(6);
        $overlay.css({
            'opacity': targetOpacity
        });
        $project.attr('bgopacity', targetOpacity);
    }
};

$(function () {
    if(!$project.hasClass('no-fade-out')) {
        $(window).scroll(function () {
            if ($(window).width() <= 752) {
                backgroundTransition(0.2);
            }
            else {
                backgroundTransition(0.3);
            }
        });
    }
});