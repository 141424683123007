import './_body-view-height'
import './_lazy-video'

var $body = $('body');
const date = new Date()
const hour = date.getHours()
if (hour < 5 || hour > 20) {
    $body.addClass('dark');
}
else {
    $body.addClass('light');   
}
import './_project-introduction.js';
import './_project-preview.js';
import './_browse-projects.js';
import './_scroll-to.js';
import './_hero-slideshow.js';
import './_clients.js';
import './_project.js';
import './_intersection-observer';
import './_large-text.js';
import './_time-counter.js';
import './_menu.js';
import './_menu-on-scroll.js';

import './_project-archive.js';