import moment from 'moment'

$('.time-counter').each(function(index, el) {
    var $this = $(this);

    var timestampString = $this.data('timestamp');
    var timestamp = timestampString ? moment(timestampString) : null;

    if (timestamp) {
        updateTimestamp($this, timestamp);

        setInterval(function() {
            updateTimestamp($this, timestamp);
        }, 1000);
    }

});

function updateTimestamp($element, timestamp) {
    $element.html(formattedTimeSinceDate(timestamp));
}

function formattedTimeSinceDate(date) {
    var now = moment();
    var components = [];

    var fullDuration = moment.duration(now.diff(date));
    var days = Math.floor(fullDuration.as('days'));

    if (days) {
        components.push(days);
    }

    var hourDuration = fullDuration.subtract(days, 'days');
    var hours = Math.floor(hourDuration.as('hours'));
    components.push(lpad(hours));

    var minuteDuration = hourDuration.subtract(hours, 'hours');
    var minutes = Math.floor(minuteDuration.as('minutes'));
    components.push(lpad(minutes));

    var secondsDuration = minuteDuration.subtract(minutes, 'minutes');
    var seconds = Math.floor(secondsDuration.as('seconds'));
    components.push(lpad(seconds));

    return components.join(':');
}

function lpad(number) {
    if (number < 10) {
        return '0' + number;
    }

    return number;
}
