import Glide from "@glidejs/glide";

function initVideoSlider(id) {
    var videoGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        animationDuration: 50,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });
    $('.glide.desktop video').on('ended', function() {
        videoGlide.go('>');
    });
    videoGlide.on('mount.after', function() {
        $('.hero-slideshow .glide.desktop .glide__slide--active div .video video').get(0).play();
    })
    videoGlide.on('run.after', function() {
        $('.hero-slideshow .glide.desktop .glide__slide--active:not(glide__slide--clone)').prev().find('video').get(0).pause();
        $('.hero-slideshow .glide.desktop .glide__slide--active:not(glide__slide--clone)').prev().find('video').get(0).currentTime = 0;
        $('.hero-slideshow .glide.desktop .glide__slide--active div .video video').get(0).play();
    })
    var videos = document.getElementById(id);
    if (videos && videos.querySelector('.glide__slides').children.length > 1) {
        videoGlide.mount();
    }
}

window.initVideoSlider = initVideoSlider;


function initVideoSliderMobile(id) {
    var videoGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        animationDuration: 50,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    $('.glide.mobile video').on('ended', function() {
        videoGlide.go('>');
    });
    videoGlide.on('mount.after', function() {
        $('.hero-slideshow .glide.mobile .glide__slide--active div .video video').get(0).play();
    })
    videoGlide.on('run.after', function() {
        $('.hero-slideshow .glide.mobile .glide__slide--active:not(glide__slide--clone)').prev().find('video').get(0).pause();
        $('.hero-slideshow .glide.mobile .glide__slide--active:not(glide__slide--clone)').prev().find('video').get(0).currentTime = 0;
        $('.hero-slideshow .glide.mobile .glide__slide--active div .video video').get(0).play();
    })
    var videos = document.getElementById(id);
    if (videos && videos.querySelector('.glide__slides').children.length > 1) {
        videoGlide.mount();
    }
}

window.initVideoSliderMobile = initVideoSliderMobile;

var $slideshow = $('.hero-slideshow').find('.glide__track');

$slideshow.click(function() {
    $('html, body').animate({
        scrollTop: $('#work').offset().top - (14 || 0)
    }, 300);
});