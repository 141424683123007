import { intersectionObserve } from "./_intersection-observer";

var largeHeadings = document.querySelectorAll('h1:not(.article-title)');
var articleTitle = document.querySelector('.article-title');
var parentContainers = [];

for (var i = 0; i < largeHeadings.length; i++) {
    var headingText = largeHeadings[i].innerHTML;
    var split = headingText.split(/(?:\s|<br>)/g);
    var parent = largeHeadings[i].parentNode;
    var newContainerDiv = document.createElement("DIV");
    newContainerDiv.setAttribute("class", "heading-wrapper");
    for (var j = 0; j < split.length; j++) {
        var newDiv = document.createElement("DIV");
        newDiv.setAttribute("class", "word-wrapper");
        if (!split[j] == " ") {
            newDiv.innerHTML = "<h1>" + split[j] + "</h1>";
        }
        else {
            newDiv.classList.add("break-line");
        }
        parent.appendChild(newContainerDiv);
        newContainerDiv.appendChild(newDiv);
    }
    if (articleTitle) {
        parentContainers.push(articleTitle);
    }
    parentContainers.push(largeHeadings[i].parentNode);
}
$('.flexible-text-content .large-text-with-subtitle .row .heading-wrapper').addClass('set-overflow');
$('.flexible-text-content .large-text .heading-wrapper').addClass('set-overflow');

var $largeTextWrapper = $('.large-text-with-subtitle .row .heading-wrapper');
var $subtitleHeight = $('.large-text-with-subtitle .row p').outerHeight();
if ($(window).width() < 1040) {
    $largeTextWrapper.css({
        'top': $subtitleHeight + 12 + 'px'
    });
}
else {
    $largeTextWrapper.css({
        'top': $subtitleHeight + 20 + 'px'
    });
}

intersectionObserve(parentContainers, 0, function (element, intersectionRatio) {
    var title = [];
    if (element.childNodes.length == 1) {
        title.push(articleTitle);
    }
    for (var i = 0; i < element.children.length; i++) {
        if (element.children[i].tagName == "DIV") {
            var headingWrapper = element.children[i];
            var wordWrapper = headingWrapper.children;
            for (var j = 0; j < wordWrapper.length; j++) {
                var titles = wordWrapper[j].children;
                for (var k = 0; k < titles.length; k++) {
                    title.push(titles[k]);
                }
            }
        }
    }
    if (intersectionRatio > 0) {
        var children = element.children;
        for (var j = 0; j < children.length; j++) {
            if (children[j].classList.length > 0 && children[j].classList.contains('set-overflow')) {
                var child = children[j];
                setTimeout(function() {
                    child.classList.remove('set-overflow');
                }, 600);
            }
        } 
    }
    for (var i = 0; i < title.length; i++) {
        if (intersectionRatio > 0 && title[i].classList.contains('animate')) {
            return;
        }
        else if (intersectionRatio > 0) {
            title[i].classList.add('animate');
        }
    }
});