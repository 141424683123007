var $multipleImagesProject = $('.projects-preview .images-wrapper');
var $image = $('.projects-preview .project .image');
var $title = $('.projects-preview .title');
var $body = $('body');
var $overlays = $('.projects-preview .overlay');
var $info = $('.projects-preview .info');
var $header = $('header');
var $introductionFacts = $('#content-section .introduction-facts');
var $introductionText = $('#content-section .introduction-text');

function projectHover($this) {
    var $overlay = $this.find('.overlay');
    var $projectInfo = $this.parent().find('.info');
    if ($body.hasClass('light')) {
        if ($this.parent().hasClass('text-color-white')) {
            $header.addClass('white-text');
            $overlays.css({
                'background-color': '#000'
            });
            $body.css({
                'background-color': '#000'
            });
            $header.css({
                'background-color': '#000'
            })
            $introductionFacts.find('p').not('.mid-grey').css({
                'color': '#fff'
            })
            $introductionFacts.find('h1').css({
                'color': '#fff'
            })
            $introductionText.find('h2').css({
                'color': '#fff'
            })
            $introductionText.find('p').not('.mid-grey').css({
                'color': '#fff'
            })
        }
        else {
            $header.addClass('black-text');
            $overlays.css({
                'background-color': '#fff'
            });
            $body.css({
                'background-color': '#fff'
            });
            $header.css({
                'background-color': '#fff'
            })
            $introductionFacts.find('p').not('.mid-grey').css({
                'color': '#000'
            })
            $introductionFacts.find('h1').css({
                'color': '#000'
            })
            $introductionText.find('h2').css({
                'color': '#000'
            })
            $introductionText.find('p').not('.mid-grey').css({
                'color': '#000'
            })
        }
    }
    else {
        if ($this.parent().hasClass('dark-mode-color-white')) {
            $header.addClass('white-text');
            $overlays.css({
                'background-color': '#000'
            });
            $body.css({
                'background-color': '#000'
            });
            $header.css({
                'background-color': '#000'
            })
            $introductionFacts.find('p').not('.mid-grey').css({
                'color': '#fff'
            })
            $introductionFacts.find('h1').css({
                'color': '#fff'
            })
            $introductionText.find('h2').css({
                'color': '#fff'
            })
            $introductionText.find('p').not('.mid-grey').css({
                'color': '#fff'
            })
        }
        else {
            $header.addClass('black-text');
            $overlays.css({
                'background-color': '#fff'
            });
            $body.css({
                'background-color': '#fff'
            });
            $header.css({
                'background-color': '#fff'
            })
            $introductionFacts.find('p').not('.mid-grey').css({
                'color': '#000'
            })
            $introductionFacts.find('h1').css({
                'color': '#000'
            })
            $introductionText.find('h2').css({
                'color': '#000'
            })
            $introductionText.find('p').not('.mid-grey').css({
                'color': '#000'
            })
        }
    }

    $info.addClass('fade-out');
    $projectInfo.removeClass('fade-out');
    $this.parent().addClass("title-hover");
    $overlays.addClass('show');
    $overlay.removeClass('show');
    $this.parent().addClass('hover');
}

function projectHoverOut($this) {
    $overlays.removeClass('show');
    $info.removeClass('fade-out');
    if ($body.hasClass('light')) {
        $body.css({
            'background-color': 'initial'
        });
        $header.css({
            'background-color': '#fff'
        });
        $introductionFacts.find('p').not('.mid-grey').css({
            'color': '#000'
        })
        $introductionFacts.find('h1').css({
            'color': '#000'
        })
        $introductionText.find('h2').css({
            'color': '#000'
        })
        $introductionText.find('p').not('.mid-grey').css({
            'color': '#000'
        })
        if ($this.parent().hasClass('text-color-white')) {
            $header.removeClass('white-text');
        }
        else {
            $header.removeClass('black-text');
        }
    }
    else {
        $body.css({
            'background-color': '#000'
        });
        $header.css({
            'background-color': '#000'
        });
        $introductionFacts.find('p').not('.mid-grey').css({
            'color': '#fff'
        })
        $introductionFacts.find('h1').css({
            'color': '#fff'
        })
        $introductionText.find('h2').css({
            'color': '#fff'
        })
        $introductionText.find('p').not('.mid-grey').css({
            'color': '#fff'
        })
        if ($this.parent().hasClass('text-color-white')) {
            $header.removeClass('white-text');
        }
        else {
            $header.removeClass('black-text');
        }
    }
    $this.parent().removeClass("title-hover");
    $this.parent().removeClass('hover');
}

if($(window).width() > 1040) {
    $($image).hover(function () {
        projectHover($(this));
    }, function () {
        projectHoverOut($(this));
    });

    $multipleImagesProject.hover(function () {
        projectHover($(this));
    }, function () {
        projectHoverOut($(this));
    });
}