const $projectBlock = $('section.project-archive');

if($projectBlock.length > 0) {
    let $filterCats = $projectBlock.find('.project-filter a');

    $filterCats.click(function(e) {
        e.preventDefault();
        let $slug = $(this).data('slug');
        $filterCats.removeClass('active');
        $(this).addClass('active');
        $('.project').removeClass('filtered');
        if($slug == 'all') {
            $('.project').show();
        }
        else {
            $('.project').hide();
            $('.project.' + $slug).show();
            $('.project.' + $slug).addClass('filtered');
        }
    });

    if($(window).width() > 1040) {
        let $projects = $projectBlock.find('.project-wrapper .project a');
        $($projects).hover(function () {
            projectHover($(this));
        }, function () {
            projectHoverOut($(this));
        });

        function projectHover($elem) {
            if($elem.find('video').length > 0) {
                $elem.find('.lazy-video-wrapper').addClass('play');
                $elem.find('video').get(0).play();
            }
            $elem.find('.info .description').addClass('show');
        }
        function projectHoverOut($elem) {
            $elem.find('.info .description').removeClass('show');
            if($elem.find('video').length > 0) {
                $elem.find('.lazy-video-wrapper').removeClass('play');
                setTimeout(function() {
                    $elem.find('video').get(0).pause();
                    $elem.find('video').get(0).currentTime = 0;
                }, 300);
            }
        }
    }
}
