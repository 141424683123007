var $viewMore = $('.project-introduction .more-info .view-more');
var $points = $('.project-introduction .points');

$viewMore.on('click', function (e) {
    e.preventDefault();
    if ($points.hasClass('show')) {
        $(this).html('View');
        $points.removeClass('show');
    }
    else {
        $(this).html('Hide');
        $points.addClass('show');
    }
});