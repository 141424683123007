var $project = $('.browse-projects .project');

if ($(window).width() >= 1040) {
    $project.hover(function () {
        $project.addClass('fade-out');
        $(this).removeClass('fade-out');
    }, function() {
        $project.removeClass('fade-out');
    });
}

$(window).resize(function() {
    if ($(window).width() >= 1040) {
        $project.hover(function () {
            $project.addClass('fade-out');
            $(this).removeClass('fade-out');
        }, function() {
            $project.removeClass('fade-out');
        });
    }
});