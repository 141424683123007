var $header = $('header');
var $headerMenu = $('header .menu-overlay');
var $headerHeight = $header.outerHeight();
var $projectTitle = $('.post-type-project .article-title');
var $imageHeight = $('article.post-type-project .thumbnail-image').outerHeight();
var targetOpacity = 0;
var headerHeight = $header.height();
var lastScrollTop = 0;
 $(window).scroll(function (e) {
    var scrollTop = $(this).scrollTop();
    if($(window).width() <= 752) {
        backgroundTransition(0.2);
    }
    else {
        backgroundTransition(0.3);
    }
    if (scrollTop === 0) {
        $header.removeClass('sticky show animate');
        $headerMenu.css({
            'background-color': 'transparent',
            'opacity': 0
        });
        $header.removeClass('set-bg-color');
    } else if (scrollTop > headerHeight) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            $header.addClass('sticky animate show')
            $headerMenu.addClass('show');
        } else {
            $header.addClass('sticky').removeClass('show');
        }
    }
    lastScrollTop = scrollTop;
});

function backgroundTransition($speed) {
    var scrollPos = $(document).scrollTop();
    var targetOpacity = 0;
    var $projectBgColor = $('article.post-type-project').data('bgcolor');
    if(!$('article.post-type-project').hasClass('no-fade-out')) {
        if (scrollPos > 0) {
            $header.addClass('set-bg-color');
            targetOpacity = ((($imageHeight*$speed) - scrollPos) / ($imageHeight*$speed)).toFixed(6);
            $headerMenu.css({
                'background-color': $projectBgColor,
                'opacity': targetOpacity
            });
        }
    }
    else {
        $headerMenu.css({
            'background-color': $projectBgColor,
            'opacity' : 1
        });
    }
};