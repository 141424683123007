var $client = $('.clients .client');

if ($(window).width() >= 1040) {
    $client.hover(function () {
        $client.addClass('fade-out');
        $(this).addClass('show-img');
        $(this).removeClass('fade-out');
    }, function() {
        $client.removeClass('fade-out');
        $(this).removeClass('show-img');
    });
}

$(window).resize(function() {
    if ($(window).width() >= 1040) {
        $client.hover(function () {
            $client.addClass('fade-out');
            $(this).removeClass('fade-out');
        }, function() {
            $client.removeClass('fade-out');
        });
    }
});